<template>
  <div>
    <div class="w-[70%] h-[95vh] pt-[64px] ml-auto mr-auto relative">
      <div class="w-[100%] h-[3.4375rem] flex items-center justify-between">
        <div class="inline-flex justify-center items-center">
          <img src="../assets/images/welcome/Group.svg" />
          <span class="font-[900] text-[20px] leading-[42px] ml-[18.8992px]">
            Matheus Campos
          </span>
        </div>
        <div class="inline-flex items-center justify-between w-[60%]">
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#help">Help</a>
          <a href="#service">Service</a>
          <a href="#contact">Contact</a>
        </div>
      </div>

      <div class="w-[30%] absolute left-0 top-[34%]">
        <div>
          <span
            class="font-400 text-[16px] leading-[19px] text-[#828282] mb-[8px]"
            >Login</span
          >
          <v-text-field
            v-model="username"
            outlined
            placeholder="Foydalanuvchi nomi"
            @keyup.enter="signIn"
          ></v-text-field>
        </div>
        <div>
          <span
            class="font-400 text-[16px] leading-[19px] text-[#828282] mb-[8px]"
            >Parol</span
          >
          <v-text-field
            type="password"
            v-model="password"
            outlined
            placeholder="Foydalanuvchi paroli"
            @keyup.enter="signIn"
          ></v-text-field>
        </div>

        <button class="w-full h-[56px] rounded bg-green-500" @click="signIn">
          <span
            class="font-600 text-[16px] leading-[21px] text-white text-center"
            >Kirish</span
          >
        </button>
      </div>

      <div
        class="inline-flex items-center justify-between w-[172px] h-[43px] absolute bottom-0 left-0"
      >
        <img src="../assets/images/welcome/Git.svg" />
        <img src="../assets/images/welcome/Linkedin.svg" />
        <img src="../assets/images/welcome/Instagram.svg" />
      </div>

      <img
        src="../assets/images/welcome/Home_art 1.svg"
        class="absolute bottom-0 right-0 w-[50%] h-[75%]"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
const { VUE_APP_WELCOME_URL, VUE_APP_BASE_URL } = process.env;

export default {
  name: "Welcome",
  data: () => ({
    username: "",
    password: "",
  }),
  methods: {
    async signIn() {
      try {
        await axios.get(VUE_APP_WELCOME_URL + "/sanctum/csrf-cookie");

        const { data } = await axios.post(VUE_APP_BASE_URL + "/sign-in", {
          username: this.username,
          password: this.password,
        });

        localStorage.setItem("token", data.token);
        this.$router.push("/");
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },
  },
};
</script>
